.savings-initial-content {
    font-weight: 800;
    font-size: 1.25rem;
    padding-right: 30%;
}

.savings-title {
    color: #9CA5FF;
    font-weight: 800;
}
.related-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #9CA5FF;
    margin-left:5%;
}

.savings-sub-title {
    font-size: 0.75rem;
}

.savings-container-border {
    border-right: 1px solid #FFFFFF;
}

.savings-container {
    padding: 2rem !important;
}

.savings-image-container {
    padding-left: 5rem;
}

.savings-image-container-wrapper {
    display: flex;
    align-items: center;
}

.savings-button {
    background-color: #9CA5FF;
    border: 0;
}
.iqz-container{
    background-color: #09101B;
}