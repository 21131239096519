.sunlife {
    background-color: #fff;
}
.navbar-finance {
    background-color: #001f5f;
}
.navbar-primerica {
    background-color: #001f5f;
}
.navbar-insurance {
    background-color: #001f5f;
}
.navbar-sunlife {
    background-color: #ffcb05;
    img {
        max-height: 50px;
    }
}
.iqz-navbar{
    color: #a8a3a3;
    margin-top: 7px;
}

.iqz-navbar:hover{
    color: #a8a3a3;
}
.finance-navbar{
    color: #a8a3a3;
    margin-top: 7px;
}
.primerica-navbar{
    color: #a8a3a3;
    margin-top: 7px;
}
.insurance-navbar{
    color: #a8a3a3;
    margin-top: 7px;
}
.finance-navbar:hover{
    color: #a8a3a3;
}
.primerica-navbar:hover{
    color: #a8a3a3;
}
.insurance-navbar:hover{
    color: #a8a3a3;
}
.sunlife-navbar{
    color: #050000;
    font-weight: 450;
    margin-top: 7px;
}
.sunlife-navbar:hover{
    color: #050000;
}
.homeicon{
    padding: 0.25rem 0.5rem;
}