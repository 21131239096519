.slider-custom-output {
    color: black;
    width: 1rem;
    margin: 1rem;
}

.slider-custom-submit {
    background-color: #9CA5FF;
}

.button-custom-container {
    margin-left: auto;
    flex-direction: column;
}

.button-custom-container-list {
    margin-left: auto;
    flex-direction: column;
    width: fit-content;
}

.custom-menu-feedback-list {
    border: 0 !important;
    padding: 0 !important;
    background-color: inherit !important;
}