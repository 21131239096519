.ads-container {
    background-color: #131E2F;
    padding: 3rem;
    border-radius: 1rem;
    overflow: auto;
}
.ads-container-sunlife {
    background-color: #d7d7d7;
}
.ads-container-sunlife p {
    color: #424242
}
.ads-container-primerica {
    background-color: #414F7A;
}
.ads-container-primerica button{
    background-color: #3E67BB;
}
.ads-container-finance {
    background-color: #414F7A;
}
.ads-container-finance button{
    background-color: #3E67BB;
}
.ads-container-finance p {
   // color: #ffffff
}

.account-statement-button {
    float: left !important;
}

.ads-image-wrapper {
    width: 100%;
    height: 0;
    padding-top: 27.6rem;
    position: relative;
}

.img-loader {
    height: 2rem;
}

.loader-wrapper {
    width: fit-content;
    margin: auto;
}

.account-statement-button:hover, 
.account-statement-button:active, 
.account-statement-button:focus {
    border-color: #9CA5FF;
    color: #FFFFFF;
}
.iqz-meetus{
    visibility: hidden;
}
.finance-meetus{
    visibility: inherit;
}
.primerica-meetus{
    visibility: hidden;
}
.insurance-meetus{
    visibility: hidden;
}
.sunlife-meetus{
    visibility: hidden;
}