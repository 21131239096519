.savings-initial-content {
    font-weight: 800;
    font-size: 1.25rem;
    padding-right: 30%;
}

.savings-title {
    color: #9CA5FF;
    font-weight: 800;
}
.related-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #9CA5FF;
    margin-left:5%;
}

.savings-sub-title {
    font-size: 0.75rem;
}

.savings-container-border {
    border-right: 1px solid #FFFFFF;
}

.savings-container {
    padding: 2rem !important;
}

.savings-image-container {
    padding-left: 5rem;
}

.savings-image-container-wrapper {
    display: flex;
    align-items: center;
}

.savings-button {
    background-color: #9CA5FF;
    border: 0;
}
.iqz-container{
    background-color: #09101B;
}
.finance-learnmore-container{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.finance-learnmore-container p
{
    color: #001f5f;
}
.finance-learnmore-container button
{
    color: #363636;
    background-color: #8FC0BD;
    border: 2px solid #004C6C;
}

.primerica-learnmore-container{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.primerica-learnmore-container p
{
    color: #001f5f;
}
.primerica-learnmore-container button
{
    color: #363636;
    background-color: #8FC0BD;
    border: 2px solid #004C6C;
}
.insurance-learnmore-container{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.insurance-learnmore-container p
{
    color: #001f5f;
}
.insurance-learnmore-container button
{
    color: #363636;
    background-color: #8FC0BD;
    border: 2px solid #004C6C;
}