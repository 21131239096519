.App {
    text-align: center;
    height: 100%;
    width: 100%;
  }
  
  .app-label{
    width: 50%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: white;
    pointer-events: none;
  }
  
  .map-container{
    height: 100vh;
    width: 100%;
  }
  
  .clicked-coord-label{position: absolute;
    color: black;
    font-weight: 500;
    right: 0;
    bottom: 0;
    background: transparent;
    border-radius: 5px;
    z-index: 2;
    background-color: #555;
  }

  .clicked-coord-label span {
    position: absolute;
    background-color: #555;
    color: #fff;
}
.displayNone{
  display: none;
}
.displayBlock{
  display: block;
}