.sunlife {
    background-color: #fff;
}
.navbar-sunlife {
    background-color: #ffcb05;
    img {
        max-height: 50px;
    }
}
.navbar-pega {
    background-color: #ffff;
    img {
        max-height: 50px;
    }
}
.iqz-navbar{
    color: #a8a3a3;
    margin-top: 7px;
}

.iqz-navbar:hover{
    color: #a8a3a3;
}
.sunlife-navbar{
    color: #050000;
    font-weight: 450;
    margin-top: 7px;
}
.sunlife-navbar:hover{
    color: #050000;
}

.hackathonPrimBtn {
    background-color: #005BBA !important;
    color: #fff !important;
}
.hackthonActiveLink a{
    color: #597CA0 !important;
}
.hackathonLink a{
    color: #333333 !important;
    font-weight: 500;
}
.hacknavbar {
    background: #ffffffab;
    .navbar-brand {
        margin-left: 3rem;
    }
}