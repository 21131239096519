.account-card-container {
    background-color: #131E2F;
    border-radius: 0.75rem;
    padding: 0.5rem;
}
.sunlife-container .account-card-container {
    background-color: #F4F7F6;
}
.finance-container .account-card-container {
    background-color: #5F68AC;
}
.finance-button{
    background-color: #8FC0BD;
}
.primerica-container .account-card-container {
    background-color: #5F68AC;
}
.primerica-button{
    background-color: #8FC0BD;
}
.insurance-container .account-card-container {
    background-color: #5F68AC;
}
.insurance-button{
    background-color: #8FC0BD;
}

.imagebackground
{
    border-radius: 50%;
    background-image: linear-gradient(to bottom,#ffcb05 70%,#eaab00 82%);
    padding: 4px !important;
}

.sunlife-image{
    border-radius: 50%;
    width: 220px;
    height: 220px;
    vertical-align: middle;
}

.account-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #9CA5FF;
}
.sunlife-container.account-title {
    color: #424242;
}
.finance-container.account-title {
    color: #001f5f;
}
.primerica-container.account-title {
    color: #001f5f;
}
.insurance-container.account-title {
    color: #001f5f;
}



.account-card-title {
    color: #9CA5FF;
}
.finance-card-title{
    color: #fcfcff;
}
.primerica-card-title{
    color: #fcfcff;
}
.insurance-card-title{
    color: #fcfcff;
}

.account-card-subtitle {
    font-weight: bold;
    font-size: 1.25rem;
}
.sunlife-container .account-card-subtitle {
    color: #424242;
}


.account-statement-button {
    padding: 0.5rem 1rem;
    background-color: #131E2F;
    border: 1px solid #9CA5FF;
    border-radius: 0.4rem;
    color: #FFFFFF;
    float: right;
}

.transactions-container {
    background-color: #131E2F;
    padding: 3rem;
    border-radius: 1rem;
    margin-right: 0.75rem;
    margin-top: 3rem;
}
.finance-container.transactions-container, .finance-container.transactions-container .table{
    background-color: #5F68AC;
}
.primerica-container.transactions-container, .primerica-container.transactions-container .table{
    background-color: #5F68AC;
}
.insurance-container.transactions-container, .insurance-container.transactions-container .table{
    background-color: #5F68AC;
}
.sunlife-container.transactions-container, .sunlife-container.transactions-container .table {
    background-color: #F4F7F6;
    color: #424242;
}

.sunlife-container .table button, .ads-container-sunlife .button {
    color: #004C6C;
    background-color: transparent;
    border: 2px solid #004C6C;
}
.finance-container .table button, .ads-container-finance .button {
    color: #363636;
    background-color: #8FC0BD;
    border: 2px solid #004C6C;
}
.primerica-container .table button, .ads-container-primerica .button {
    color: #363636;
    background-color: #8FC0BD;
    border: 2px solid #004C6C;
}
.insurance-container .table button, .ads-container-insurance .button {
    color: #363636;
    background-color: #8FC0BD;
    border: 2px solid #004C6C;
}