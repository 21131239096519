.iqz-feedbackdetails{
    background-color: #09101B;
    height:100vh;
}
.finance-feedbackdetails{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}

.primerica-feedbackdetails{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.insurance-feedbackdetails{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.sunlife-feedbackdetails{
    background-color: transparent;
}
.iqz-feedbackdetails input {
    margin : 12px;
}