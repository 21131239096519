@import "~bulma-carousel";
.header-body {
    align-items: center;
}

.header-content {
    font-size: 3rem;
    color: #FFFFFF;
    font-weight: bold;
}

.hero-image {
    width: 100%;
}

@media only screen and (max-width: 770px) {
    p {
        padding: 0 3rem !important;
    }

    p:nth-child(2) {
        margin-bottom: 3rem;
    }
}
.carousel-conainter-margin{
    margin-top:0;
    margin-bottom:0;
}
.slider-container{
    height:30rem;
}
.slider-navigation-previous, .slider-navigation-next{
    background: center center no-repeat;
    visibility: hidden;
}
.domain-image {
    border-radius: 0.75rem;
}
.hero-carousel { overflow: hidden; }
// .hero-carousel {
//     .slider {
//       .slider-container {
//         .slider-item {
//           width: 50rem !important;
//         }
//       }
//     }
//   }