.insurance-products-container{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.insurance-products-container p
{
    color: #001f5f;
}
.insurance-products-container button
{
    color: #363636;
    background-color: #8FC0BD;
    border: 2px solid #004C6C;
}
.insurance-page-background{
    background-color: transparent;
}
.iqz-page-background{
    background-color: #09101B;;
}
