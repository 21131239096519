.domain-title {
    font-size: 2rem;
    font-weight: bold;
}

li {
    border-radius: 1.5rem;
    border-bottom: 5px solid #47AEFF;
    padding: 2rem;
    background-color: #131E2F;
    height: 100%;
}

li p {
    text-align: center;
    margin-top: 1rem;
}

.card-grid {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(6, 1fr);
    max-width: 80%;
    margin: auto;
}

.image-container {
    width: fit-content;
    margin: auto;
    height: 130px;
    display: flex;
}

@media only screen and (min-width: 750px) and (max-width: 1300px) {
    .card-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 750px) {
    .card-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}