.links-container {
    background-color: #131E2F;
    padding: 3rem;
    border-radius: 1rem;
    margin-top: 3rem;
    overflow: auto;
}
.sunlife-container.links-container {
    background-color: #F4F7F6;
}
.finance-container.links-container {
    background-color: #414F7A;
}
.primerica-container.links-container {
    background-color: #414F7A;
}
.insurance-container.links-container {
    background-color: #414F7A;
}
.quick-links-button {
    background-color: #233653;
    color: #FFFFFF;
    padding: 0.5rem 0.75rem;
    border: 0;
    border-radius: 0.25rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.sunlife-container .quick-links-button {
    background-color: #4B7283;
}
.finance-container .quick-links-button {
    background-color: #3E67BB;
}
.finance-container .account-title{
    color:white;
}
.primerica-container .quick-links-button {
    background-color: #3E67BB;
}
.primerica-container .account-title{
    color:white;
}
.insurance-container .quick-links-button {
    background-color: #3E67BB;
}
.insurance-container .account-title{
    color:white;
}