.iqzdropdown{
    padding-right: 10px;
}
.iqz-dropdownLabel{
    padding: 10px;
}
.sunlife-dropdownLabel{
    padding: 10px;
    // color:black;
}
.iqz-header-content {
    font-size:2em;
}
.sunlife-header-content {
    font-size:2em;
    color:white
}
.iqz-container{
    background-color: #09101B;
}
.displayNotification{
    display: block;
}
.hideNotification{
    display: none;
}