.iqz-accountdetails{
    background-color: #09101B;
}
.finance-accountdetails{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.primerica-accountdetails{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.insurance-accountdetails{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.sunlife-accountdetails{
    background-color: transparent;
}