// .footer {
    // margin-top: 4rem;
// }
.sunlife-footer {
    background-color: #004c6c;
    margin-top: 0;
}
.finance-footer {
    background-image: url("/assets/images/fn-footer.png");
    margin-top: 0;
    padding-top: 10rem;
}
.finance-footer-text{
    color:black;
}
.finance-footer-text> a{
    color:black;
}

.primerica-footer {
    background-image: url("/assets/images/fn-footer.png");
    margin-top: 0;
    padding-top: 10rem;
}
.primerica-footer-text{
    color:black;
}
.primerica-footer-text> a{
    color:black;
}
.insurance-footer {
    background-image: url("/assets/images/fn-footer.png");
    margin-top: 0;
    padding-top: 10rem;
}
.insurance-footer-text{
    color:black;
}
.insurance-footer-text> a{
    color:black;
}
span {
    margin-right: 3rem;
}

.social-icons {
    float: right;
}

@media only screen and (max-width: 750px) {
    span {
        display: block;
        margin-top: 2rem;
    }

    .social-icons {
        float: left;
        display: block;
    }
}