.login-container {
    background-color: #131E2F;
    border-radius: 3rem;
    justify-content: center;
    padding: 5rem;
}

.sunlife-container.login-container {
    background-color: #F4F7F6;
}
.finance-container.login-container
{
    padding:4rem;
    background-color: #414F7A;
}
.primerica-container.login-container
{
    padding:4rem;
    background-color: #414F7A;
}
.insurance-container.login-container
{
    padding:4rem;
    background-color: #414F7A;
}
.submit-button {
    background-color: #9CA5FF !important;
}

.select::after {
    border-color: #9CA5FF !important;
}

.select:hover::after {
    border-color: #131E2F !important;
}

.has-full-width {
    width: 100%;
}
.sunlife-login {
    padding-bottom: 4rem;
}
.iqz-login
{
    padding-bottom: 3rem !important;
    background-color: #09101B;
}
.finance-login
{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.primerica-login
{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.insurance-login
{
    background-color: #abc2f2;
    background-image: url(/assets/images/fn-bg.png);
}
.iqz-login-text {
    color: white !important;
}
.finance-login-text {
    color: white !important;
}
.primerica-login-text {
    color: white !important;
}
.insurance-login-text {
    color: white !important;
}