.modal-card-foot{
    justify-content: flex-end;
}

.modal-card {
   width: auto;
} 
.control{
    color:#363636
}
.checkbox input, .radio input{
    margin-right:3px;
}
.Toastify__toast-theme--colored.Toastify__toast--default, .Toastify__toast-theme--light{
    background: #fff !important;
}